import React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Disqus } from 'gatsby-plugin-disqus'
import { css } from '@emotion/core'


import Bio from '../components/bio'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Newsletter from '../components/newsletter'
import Banner from '../components/banner'
import Share from '../components/share'
import ReadingTime from '../components/readingTime'
import { rhythm } from '../utils/typography'
import config from '../../gatsby-config'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const blogPostUrl = `${config.siteMetadata.siteUrl}${post.fields.slug}`
    const twitterHandle = config.siteMetadata.social.twitter

    const disqusConfig = {
      url: `${config.siteMetadata.siteUrl}${this.props.location.pathname}`,
      identifier: post.fields.slug,
      title: post.frontmatter.title,
    }

    const image = post.frontmatter.banner ? post.frontmatter.banner.childImageSharp.fluid : null

    return (
      <Layout location={this.props.location} title={siteTitle} frontmatter={post.frontmatter}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          type="article"
          publishedDate={post.frontmatter.date}
          updatedDate={post.frontmatter.updated}
          url={post.fields.slug}
          image={image}
        />
        <article>
          <header>
            <h1
              style={{
                marginTop: 0,
                marginBottom: `1.55rem`,
                textAlign: `center`,
              }}
            >
              {post.frontmatter.title}
            </h1>
            {post.frontmatter.banner && (
              <Banner frontmatter={post.frontmatter} />
            )}
            {!post.frontmatter.hideTime && (
              <ReadingTime readingTimeInMinutes={post.fields.readingTime.minutes}
                           updatedDate={post.frontmatter.updated} />
            )}
          </header>
          <MDXRenderer>{post.body}</MDXRenderer>
        </article>

        {!post.frontmatter.landing && (
          <div>
            {post.frontmatter.hideTime && <hr
              style={{
                marginBottom: rhythm(1),
              }}
            />}
            <Newsletter />
            <hr />
            <Bio />
          </div>
        )}

        {/* FIXME: This is a useful hack. Need to rething how to do it better. Probably entry type is needed. */}
        {!post.frontmatter.hideTime && (
          <>
            <div style={{ marginBottom: 40 }}>
              <Share
                url={blogPostUrl}
                title={post.frontmatter.title}
                twitterHandle={twitterHandle}
              />
            </div>
          </>
        )}

        {!post.frontmatter.landing && !post.frontmatter.hideTime && (
          <Disqus config={disqusConfig} />
        )}

        {(previous || next) && (
          <nav
            css={css`
              ul {
                margin: 0 0 1.7rem 0;
              }
            `}
          >
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        )}
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD", locale: "EN")
        updated(formatString: "YYYY-MM-DD", locale: "EN")
        description
        hero
        banner {
          ...bannerImageFull
        }
        bannerCredit
        hideTime
        landing
      }
      fields {
        slug
        readingTime {
          minutes
        }
      }
    }
  }
`
