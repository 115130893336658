const rssTitle = `AWS Maniac - Posts`;

module.exports = {
  siteMetadata: {
    title: `AWS Maniac`,
    rssTitle,
    author: `Wojciech Gawroński`,
    description: `AWS Madness? Trust the AWS Maniac!`,
    siteUrl: `https://awsmaniac.com`,
    social: {
      facebook: `afronski`,
      instagram: `afronsky`,
      twitter: `afronski`,
      github: `afronski`,
      linkedin: `afronski`,
      youtube: `UCGpHK4MYiIMbyJt7TOkcp5Q`,
    },
    convertKitFormId: 1881515,
  },
  plugins: [
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        path: `${__dirname}/src/content/blog`,
        name: `blog`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        path: `${__dirname}/src/content/page`,
        name: `page`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        path: `${__dirname}/src/content/assets`,
        name: `assets`,
      },
    },
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        exclude: [
          `/frontmatter-placeholder/`
        ],
      }
    },
    {
      resolve: `gatsby-plugin-mdx`,
      options: {
        extensions: [`.mdx`, `.md`],
        plugins: [
          `gatsby-remark-images`,
          `gatsby-remark-images-medium-zoom`,
          `gatsby-remark-responsive-iframe`,
        ],
        gatsbyRemarkPlugins: [
          {
            resolve: `gatsby-remark-images`,
            options: {
              maxWidth: 590,
              linkImagesToOriginal: false
            },
          },
          {
            resolve: `gatsby-remark-images-medium-zoom`,
            options: {},
          },
          {
            resolve: `gatsby-remark-responsive-iframe`,
            options: {
              wrapperStyle: `margin-bottom: 1.0725rem`,
            },
          },
          `gatsby-remark-prismjs`,
          `gatsby-remark-copy-linked-files`,
          `gatsby-remark-smartypants`,
        ],
      },
    },
    `gatsby-remark-reading-time`,
    {
      resolve: `gatsby-transformer-sharp`,
      options:{
        checkSupportedExtensions: false,
      }
    },
    `gatsby-plugin-sharp`,
    `gatsby-plugin-emotion`,
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: `UA-116913813-6`,
      },
    },
    {
      resolve: `gatsby-plugin-feed-mdx`,
      options: {
        query: `
          {
            site {
              siteMetadata {
                title
                description
                siteUrl
                site_url: siteUrl
              }
            }
          }
        `,
        feeds: [
          {
            serialize: ({
              query: {
                site,
                allMdx,
              } }) => allMdx.edges.map(edge => Object.assign(
              {},
              edge.node.frontmatter,
              {
                description: edge.node.frontmatter.description,
                date: edge.node.frontmatter.date,
                url: site.siteMetadata.siteUrl + edge.node.fields.slug,
                guid: site.siteMetadata.siteUrl + edge.node.fields.slug,
                custom_elements: [
                  {
                    'content:encoded': `<div style="width: 100%; margin: 0 auto; max-width: 800px; padding: 40px 40px;">
                        <p>
                          I have published a new post <em>"${edge.node.frontmatter.title}"</em>, which you can read <a href="${site.siteMetadata.siteUrl + edge.node.fields.slug}">here</a>.
                          <br/>
                          ${edge.node.frontmatter.description}
                          <br/>
                          Alternatively, you can subscribe to the <a href="${site.siteMetadata.siteUrl}/newsletter">newsletter</a> to be notified about new entries via email.
                        </p>
                      </div>`,
                  },
                ],
              }
            )),
            query: `
              {
                allMdx(
                  filter: {
                    frontmatter: {published: {ne: false}}
                    fileAbsolutePath: {regex: "//src/content/blog//"}
                  }
                  sort: { order: DESC, fields: [frontmatter___date] },
                ) {
                  edges {
                    node {
                      excerpt
                      html
                      fields { slug }
                      frontmatter {
                        title
                        date
                        description
                      }
                    }
                  }
                }
              }
            `,
            output: '/rss.xml',
            title: rssTitle,
            match: "^/blog/"
          },
        ],
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `awsmaniac.com`,
        short_name: `awsmaniac.com`,
        start_url: `/`,
        background_color: `#ffffff`,
        theme_color: `#242e3e`,
        display: `minimal-ui`,
        icon: `src/content/assets/icon.png`
      },
    },
    `gatsby-plugin-remove-serviceworker`,
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-plugin-react-helmet-canonical-urls`,
      options: {
        siteUrl: `https://awsmaniac.com`,
      },
    },
    {
      resolve: `gatsby-plugin-typography`,
      options: {
        pathToConfigModule: `src/utils/typography`,
        omitGoogleFont: true,
      },
    },
    {
      resolve: `gatsby-plugin-disqus`,
      options: {
        shortname: `aws-maniac`,
      },
    },
  ],
}
