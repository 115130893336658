import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import { rhythm } from '../utils/typography'

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/hero.png/" }) {
        childImageSharp {
          fixed(width: 80, height: 80, quality: 90) {
            ...GatsbyImageSharpFixed
          },
        }
      }
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(1),
      }}
    >
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          minWidth: 80,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <p>
        👋 Hey! My name is Wojciech Gawroński, but some people call me <strong><em>AWS Maniac</em></strong>. I am your trusted guide through the <em>AWS Madness</em>. If you want to learn more about me, you can <Link style={{ fontWeight: 'bold' }} to="/start-here">start here</Link>.
      </p>
    </div>
  )
}

export default Bio
