import React from 'react'
import { css } from '@emotion/core'
import { TwitterShareButton, FacebookShareButton, LinkedinShareButton } from 'react-share'

import { Colors, Fonts } from '../utils/layoutUtils'

const Share = ({ url, title, twitterHandle }) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-family: ${Fonts.SANS};
      font-size: 80%;
      div {
        cursor: pointer;
        :hover {
          color: ${Colors.MAIN};
        }
      }
      span {
        text-transform: uppercase;
        line-height: 2.5;
        opacity: 0.7;
      }
    `}
  >
    <div
      css={css`
        flex-grow: 1;
        border-top: 1px solid hsla(0,0%,0%,0.2);
      `}
    />
    <span
      css={css`
        margin-right: 0.5rem;
      `}
    >Share it:</span>
    <FacebookShareButton
      url={url}
      quote={title}
      css={css`
        cursor: pointer;
        margin-right: 0.5rem;
      `}
    >
      Facebook
    </FacebookShareButton>
    <TwitterShareButton
      url={url}
      title={title}
      via={twitterHandle.split('@').join('')}
      css={css`
        cursor: pointer;
        margin-right: 0.5rem;
      `}
    >
      Twitter
    </TwitterShareButton>
    <LinkedinShareButton
      url={url}
      title={title}
      css={css`
        cursor: pointer;
      `}
    >
      LinkedIn
    </LinkedinShareButton>

  </div>
)

export default Share
